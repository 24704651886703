import LanguageDetector from 'i18next-browser-languagedetector';
import i18n from "i18next";
import {
    initReactI18next
} from 'react-i18next';

import jaJpTrans from "./locales/ja-jp.json";
import zhCnTrans from "./locales/zh-cn.json";
import usEnTrans from "./locales/us-en.json";

let lang = localStorage.getItem('lang')

if(window.location.search.indexOf('zh')>-1){
    lang = 'zh'
    localStorage.setItem('lang', 'zh')
}


i18n
    // .use(LanguageDetector) //嗅探当前浏览器语言
    .use(initReactI18next) //init i18next
    .init({
        //引入资源文件
        resources: {
            ja: {
                translation: jaJpTrans,
            },
            zh: {
                translation: zhCnTrans,
            },
            en: {
                translation: usEnTrans
            }
        },
        //选择默认语言，选择内容为上述配置中的key，即en/zh
        fallbackLng: lang ? lang : 'ja',
        debug: false,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
    })

export default i18n;
