import './App.less';
import Header from './component/Header'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import Index from './pages/Index.jsx'
// import Footer from './component/Footer'
import Footer2 from './component/Footer2'
import Detail from './pages/Detail'
import Professor1 from './pages/Professor1'
import Professor2 from './pages/Professor2'
import Professor3 from './pages/Professor3'
import '../src/i18n'
import { useTranslation } from 'react-i18next'
import JD from './assets/jd.png'
import AMAZON from './assets/amazon.png'
import V2 from './pages/v2';

function App() {

  const { t ,i18n} = useTranslation()

  const menus = [
    {
      title: t('home'),
      path: '/index',
      component: Index
    },
    {
      title: t('product-menu'),
      path: '/index#products',
      component: Index
    },
    {
      title: t('introduce'),
      path: '/index#introduce',
      component: Index
    },
    {
      title: t('contact-us'),
      path: '/index#contact',
      component: Index
    },
  ]
  return (
    <div className="App">
      <BrowserRouter>
        <Header menus={menus} />
        <Switch>
          <Route path='/detail/:id' component={Detail} />
          <Route path='/professor1' component={Professor1} />
          <Route path='/v2/:id' component={V2} ></Route>
          {/* <Route path='/professor2' component={Professor2} />
          <Route path='/professor3' component={Professor3} /> */}
          {menus.map((menu, index) => {
            return <Route key={index} path={menu.path} component={menu.component} />
          })}
          <Redirect path="/" to="/v2/p1" />
        </Switch>
        <Footer2 />
        {/* <div className="fixed-sales">
            <h1 className="title">{t('sales')}</h1>
            <div className="descs" style={{}}>
                <a  href="https://npcitem.jd.hk/10046628061834.html" target="blank"><img src={JD} alt="" /></a>
                <a  href="https://www.amazon.co.jp/dp/B09YTQFGSW?ref=myi_title_dp" target="blank"><img src={AMAZON} alt="" /></a>
            </div>
        </div> */}
      </BrowserRouter>
    </div>
  );
}

export default App;
