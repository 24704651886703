import React from 'react';
import { useTranslation } from 'react-i18next'
import Header from './component/header'
import Img1 from './imgs/矩形备份 11.png'
import Img2 from './imgs/psc1.png'
import Pm from './component/p-m'
import Func from './component/func'
import Qa from './component/qa'
import Process from './component/process'
import Chat, { Ans, Ques } from './component/chat'

function PSc() {
  const { t } = useTranslation()
  return (
    <div style={{backgroundColor: '#FAFAFA', overflow: 'hidden'}}>
      <Header t1={t('p-sc.title')} t2={t('p-sc.title2')} pic={Img1} />
      <Pm num={"01"} title={t('p-sc.pm-t-1')} contents={[
        t('p-sc.pm-d-1'),
        t('p-sc.pm-d-2'),
        t('p-sc.pm-d-3'),
        t('p-sc.pm-d-4'),
        t('p-sc.pm-d-5'),
      ]} pic={Img2} />
      <Func num="02" title={t('p-sc.func-title')} funcs={[
        {
          title: t('p-sc.func-t-1'),
          detail: t('p-sc.func-d-1')
        },
        {
          title: t('p-sc.func-t-2'),
          detail: t('p-sc.func-d-2')
        },
      ]} />
      <Qa num={"03"} title={t('p-sc.qa-title')} qas={[
        {
          q: t('p-sc.qaq1'),
          a: t('p-sc.qaa1'),
        },
        {
          q: t('p-sc.qaq2'),
          a: t('p-sc.qaa2'),
        },
        {
          q: t('p-sc.qaq3'),
          a: t('p-sc.qaa3'),
        },
        {
          q: t('p-sc.qaq4'),
          a: t('p-sc.qaa4'),
        },
        {
          q: t('p-sc.qaq5'),
          a: t('p-sc.qaa5'),
        },
        {
          q: t('p-sc.qaq6'),
          a: t('p-sc.qaa6'),
        },
        {
          q: t('p-sc.qaq7'),
          a: t('p-sc.qaa7'),
        },
      ]}/>
      <Process title={t('p-sc.title')} steps={[
        {
          title: t('p-sc.p-t-1'),
          detail: [
            t('p-sc.p-d-11'),
            t('p-sc.p-d-12')
          ]
        },
        {
          title: t('p-sc.p-t-2'),
          detail: t('p-sc.p-d-2')
        },
        {
          title: t('p-sc.p-t-3'),
          detail: t('p-sc.p-d-3')
        },
        {
          title: t('p-sc.p-t-4'),
          detail: t('p-sc.p-d-4')
        },
        {
          title: t('p-sc.p-t-5'),
          detail: t('p-sc.p-d-5')
        },
        {
          title: t('p-sc.p-t-6'),
          detail: t('p-sc.p-d-6')
        },        
      ]} />
      <Chat title={t('p-sc.chat-title')} list={[
        {
          type: Ques,
          text: t('p-sc.chat-q-1')
        },
        {
          type: Ans,
          text: t('p-sc.chat-a-1')
        },
        {
          type: Ques,
          text: t('p-sc.chat-q-2')
        },
        {
          type: Ans,
          text: t('p-sc.chat-a-2')
        },
        {
          type: Ques,
          text: t('p-sc.chat-q-3')
        },
        {
          type: Ans,
          text: t('p-sc.chat-a-3')
        },
      ]} />
    </div>
  );
}

export default PSc;
