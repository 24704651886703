import React from 'react'
import './Index.less'
import Product1 from '../assets/product1.png'
import P1 from '../assets/p1.png'
import P2 from '../assets/p2.png'
import P3 from '../assets/p3.png'

// import homeP from '../assets/home.jpeg'
import homeP from '../assets/h2.jpg'
import Pl2 from '../assets/pl2.jpg'
import Pl3 from '../assets/pl3.jpg'
import Pl4 from '../assets/pl4.jpg'
import Pl5 from '../assets/pl5.jpg'
import Pl6 from '../assets/pl6.jpg'
import Qr from '../assets/qr.jpg'
import RedBook from '../assets/redbook.png'
import Ins from '../assets/ins.jpeg'




import { useTranslation } from 'react-i18next'

export default props => {

    const { t, i18n } = useTranslation()

    const intrduces = [1, 2, 3, 4, 5, 6, 7, 8, 9]

    return <div className="index">
        <div className="banner" style={{ display: 'flex', justifyContent: 'center' }}>
            {i18n.language === 'zh' ?  <video autoplay="autoPlay" muted>
                <source src="http://file.chellon.com/202201111529.mp4" type="video/mp4" />
            </video>: <img className="banner-img" src={homeP}>
            </img>
            
            }
            
        </div>

        <div className="title clearfix">
            <a name="products"></a>
            <h1>{t('product-menu')}</h1>
            <div className="border-bottom"></div>
        </div>

        <div className="product-menu">

            <div className="product-preview" onClick={() => window.location.href = '//' + window.location.host + '/detail'+'/1'}>
                <img src={Product1} alt="" />
                <p className="name">magam³</p>
            </div>

            <div className="product-preview" onClick={() => {
                if(i18n.language === 'ja')return
                window.location.href = '//' + window.location.host + '/detail'+'/4'
            }}>
                <img src={Pl2} alt="" />
                <p className="name">Carboless</p>
            </div>

            <div className="product-preview" onClick={() => {
                if(i18n.language === 'ja')return
                window.location.href = '//' + window.location.host + '/detail'+'/6'
            }}>
                <img src={Pl3} alt="" />
                <p className="name">Crismatus</p>
            </div>

            <div className="product-preview"  onClick={() => {
                if(i18n.language === 'ja')return
                window.location.href = '//' + window.location.host + '/detail'+'/3'
            }}>
                <img src={Pl4} alt="" />
                <p className="name">Gluscle</p>
            </div>

            <div className="product-preview" onClick={() => {
                if(i18n.language === 'ja')return
                window.location.href = '//' + window.location.host + '/detail'+'/2'
            }}>
                <img src={Pl5} alt="" />
                <p className="name">Ōrakara</p>
            </div>

            <div className="product-preview" onClick={() => {
                if(i18n.language === 'ja')return
                window.location.href = '//' + window.location.host + '/detail'+'/5'
            }}>
                <img src={Pl6} alt="" />
                <p className="name">Upperbase</p>
            </div>

        </div>
        <div className="title">
            <a name="introduce"></a>
            <h1>{t('introduce')}</h1>
            <div className="border-bottom"></div>
        </div>

        <div className="introduce" style={{ background: '#FAFAFA' }} onClick={() => window.location.href = '//' + window.location.host + '/professor1'}>
            <img src={P1} alt="" />
            <div className="content">
                <h1>{t('pro-name')}</h1>
                <div className="text">
                    <p>{t('gra')} <br></br>{t('collage')}</p>
                    <p>{t('now')} <br></br>{t('position')}</p>
                </div>
            </div>
        </div>

        {/* <div className="introduce" style={{ background: '#EFF5FA' }} onClick={() => window.location.href = '//' + window.location.host + '/professor2'}>

            <div className="content">
                <h1>{t('pro-name-b')}</h1>
                <div className="text">
                    <p>{t('gra')}<br></br>  {t('collage-b')} </p>
                    <p>{t('now')} <br></br> {t('position-b')}</p>
                </div>
            </div>
            <img src={P2} alt="" />
        </div>

        <div className="introduce" style={{ background: '#FAFAFA' }} onClick={() => window.location.href = '//' + window.location.host + '/professor3'}>
            <img src={P3} alt="" />
            <div className="content">
                <h1>{t('pro-name-c')}</h1>
                <div className="text">
                    <p>{t('gra')} <br></br> {t('collage-c')} </p>
                    <p>{t('now')} <br></br> {t('position-c')}</p>
                </div>
            </div>
        </div> */}

        <div className="focus">
            <a name="contact"></a>
            <h1 className="logo-text">PROTICCS</h1>
            <div className="intrduce-con">
                {intrduces.map((val, i) => {
                    if(i18n.language === 'ja' && val===4){
                        return  <p key={i}>製品のキーワードは「<span style={{fontSize: 22, color: '#000'}}>実感</span>」！！！</p>
                    }
                    return <p key={i}>{t(`introduce${val.toString()}`)}</p>
                })}
            </div>

            {
            // i18n.language == 'ja' ? <div style={{height: 58}}></div> : 
            <div className="qr-imgs">
                <div className="qr-img">
                    <img src={RedBook} alt="" />
                    <p>{i18n.language == 'ja' ? 'RED' :'小红书'}</p>
                </div>
                <div className="qr-img">
                    <img src={Qr} alt="" />
                    <p>{i18n.language == 'ja' ? '公式アカウント' :'公众号'}</p>
                </div>
                <div className="qr-img">
                    <img src={Ins} alt="" />
                    <p>Instagram</p>
                </div>
            </div>
            }
            <p>{t('qr-desc')}</p>
            {/* <p >{t('tips')}</p> */}
        </div>

        <div className="address">
            <p>{t('address')}</p>
            <p>{t('contact')}</p>
        </div>

       

    </div>
}