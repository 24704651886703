import React from 'react';
import { useTranslation } from 'react-i18next'
import Header from './component/header'
import Img from './imgs/矩形 2 (1).png'
import Img2 from './imgs/编组 29.png'
import Img3 from './imgs/编组 28.png'
import Pm from './component/p-m'
import Qa from './component/qa'
import Process from './component/process'
import Chat,{ Ans, Ques } from './component/chat';

function Prp() {
  const { t } = useTranslation()
  return (
    <div  style={{backgroundColor: '#FAFAFA', overflow: 'hidden'}}>
      <Header t1="PRP" t2="Platelet-rich plasma" pic={Img} />
      <Pm num="01" title={t('p-prp.p-m-t')} pic={Img2} contents={[
        t('p-prp.p-m-d1'),
        t('p-prp.p-m-d2')
      ]}/>
      <Pm type='v' num="01" title={t('p-prp.p-m-t1')} pic={Img3} contents={[
        t('p-prp.p-m-d3'),
        t('p-prp.p-m-d4')
      ]}/>
      <Qa num="02" title="什么人群适合PRP皮肤再生疗法" qas={[
        {q: t('p-prp.q1')},
        {q: t('p-prp.q2')},
        {q: t('p-prp.q3')},
        {q: t('p-prp.q4')},
        {q: t('p-prp.q5')},
        {q: t('p-prp.q5')},
      ]}/>
      <Process title={t('v2.process-title')} steps={[
        {
          title: t('p-prp.p-t-1'),
          detail: [t('p-prp.p-d-11'), t('p-prp.p-d-12'),],
        },
        {
          title: t('p-prp.p-t-2'),
          detail: t('p-prp.p-d-2'),
        },
        {
          title: t('p-prp.p-t-3'),
          detail: t('p-prp.p-d-3'),
        },
        {
          title: t('p-prp.p-t-4'),
          detail: t('p-prp.p-d-4'),
        },
        {
          title: t('p-prp.p-t-5'),
          detail: t('p-prp.p-d-5'),
        },
      ]}/>
      <Chat title={t('v2.chat-title')} list={[
        {
          type: Ques,
          text: t('p-prp.chat-q1')
        },
        {
          type: Ans,
          text: t('p-prp.chat-a1')
        },
        {
          type: Ques,
          text: t('p-prp.chat-q2')
        },
        {
          type: Ans,
          text: t('p-prp.chat-a2')
        },
        {
          type: Ques,
          text: t('p-prp.chat-q3')
        },
        {
          type: Ans,
          text: t('p-prp.chat-a3')
        },
      ]}/>
    </div>
  );
}

export default Prp;
