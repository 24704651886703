import React from "react";
import "./p-nk.less";
import Header from "./component/header";
import PM from "./component/p-m";
import Func from "./component/func";
import Img from "./imgs/矩形 2.png";
import Img2 from "./imgs/编组 5.png";
import Img3 from "./imgs/编组 6.png";
import Qa from "./component/qa";
import Process from "./component/process";
import Chat, { Ques, Ans } from "./component/chat";
import { useTranslation } from "react-i18next";

function PNk() {
  const { t } = useTranslation();
  return (
    <div className="v2-nk">
      <Header t1={t("nk.title")} t2={t("nk.title2")} pic={Img} />
      <PM
        num="01"
        pic={Img2}
        title={t("nk.q1")}
        contents={[t("nk.a1"), t("nk.a2"), t("nk.a3")]}
      />
      <Func
        num="02"
        title={t("nk.f-title")}
        funcs={[
          { title: t("nk.func-title1"), detail: t("nk.func-detail1") },
          { title: t("nk.func-title2"), detail: t("nk.func-detail2") },
          { title: t("nk.func-title3"), detail: t("nk.func-detail3") },
          { title: t("nk.func-title4"), detail: t("nk.func-detail4") },
        ]}
      />
      <PM
        num="03"
        pic={Img3}
        title={t("nk.q2")}
        contents={[
          t("nk.aa2"),
        ]}
      />

      <Qa
        num="04"
        title={t("nk.qa-title")}
        qas={[
          {
            q: t("nk.qaq1"),
            a: t("nk.qaa1"),
          },
          {
            q: t("nk.qaq2"),
            a: t("nk.qaa2"),
          },
          {
            q: t("nk.qaq3"),
            a: t("nk.qaa3"),
          },
          {
            q: t("nk.qaq4"),
            a: t("nk.qaa4"),
          },
        ]}
      />
      <Process
        title={t('nk.p-title')}
        steps={[
          {
            title: t("nk.p-t-1"),
            detail: [
              t("nk.p-d-11"),
              t("nk.p-d-12"),
            ],
          },
          {
            title: t("nk.p-t-2"),
            detail: t("nk.p-d-2"),
          },
          {
            title: t("nk.p-t-3"),
            detail: t("nk.p-d-3"),
          },
          {
            title: t("nk.p-t-4"),
            detail: t("nk.p-d-4"),
          },
          {
            title: t("nk.p-t-5"),
            detail: t("nk.p-d-5"),
          },
          {
            title: t("nk.p-t-6"),
          },
        ]}
      />

      <Chat
        title={t("nk.chat-title")}
        list={[
          {
            type: Ques,
            text: t("nk.chat-q-1"),
          },
          {
            type: Ans,
            text: t("nk.chat-a-1"),
          },
          {
            type: Ques,
            text: t("nk.chat-q-2"),
          },
          {
            type: Ans,
            text: t("nk.chat-a-2"),
          },
          {
            type: Ques,
            text: t("nk.chat-q-3"),
          },
          {
            type: Ans,
            text: t("nk.chat-a-3"),
          },
        ]}
      />
    </div>
  );
}

export default PNk;
