import React from "react";
import Head1 from "../imgs/编组 3.png";
import Head2 from "../imgs/编组 4.png";
import "./chat.less";

export const Ques = "q";
export const Ans = "a";

function Chat(props) {
  const { title, list } = props;
  return (
    <div className="v2-chat">
      <div className="title">{title}</div>
      {list.map((val, index) => {
        return (
          <div className="item" key={index}>
            {val.type === Ques && <div className="ques">
              <div className="head1">
                <img src={Head1} alt="" />
              </div>
              <div className="content-ques">{val.text}</div>
            </div>}

            {val.type === Ans &&<div className="ans">
              <div className="content-ans">{val.text}</div>
              <div className="head2">
                <img src={Head2} alt="" />
              </div>
            </div>}
          </div>
        );
      })}
    </div>
  );
}

export default Chat;
