import React from 'react';
import './diff.less'
import { useTranslation } from 'react-i18next'
import Ques from './ques';
function Diff() {
  const {t} = useTranslation()
  const data = {
    subs: [
      t('diff.subs.sub1'),
      t('diff.subs.sub2'),
      t('diff.subs.sub3'),
      t('diff.subs.sub4'),
      t('diff.subs.sub5'),
      t('diff.subs.sub6'),
      t('diff.subs.sub7'),
      t('diff.subs.sub8'),
    ],
    items: [
      {
        name: t('diff.item1.name'),
        subs: [
          t('diff.null'),
          t('diff.null'),
          t('diff.item1.sub3'),
          t('diff.item1.sub4'),
          t('diff.null'),
          t('diff.item1.sub6'),
          t('diff.null'),
          t('diff.item1.sub8'),
        ]
      },
      {
        name: t('diff.item2.name'),
        subs: [
          t('diff.item2.sub1'),
          t('diff.item2.sub2'),
          t('diff.item2.sub3'),
          t('diff.item2.sub4'),
          t('diff.item2.sub5'),
          t('diff.item2.sub6'),
          t('diff.item2.sub7'),
          t('diff.item2.sub8'),
        ]
      }
    ]
  }
  return (
    <div className='p-diff'>
      <Ques num="02" title="干细胞上清液注射与干细胞医疗区别" />
      <div className="subs">
        <div className="item">
          <div className="sub">&nbsp;</div>
          {
            data.items.map((val, index)=>{
              return <div className="name" key={index}>
                {val.name}
              </div>
            })
          }
        </div>
        {
          data.subs.map((val, index)=>{
            return <div className="item" key={index}>
              <div className="sub">{val}</div>
              {
                data.items.map((item, i)=>{
                  return <div className="value">{item.subs[index]}</div>
                })
              }
            </div>
          })
        }
      </div>
    </div>
  );
}

export default Diff;
