import React from "react";
import { useTranslation } from "react-i18next";
import "./v2-p1.less";
import P1 from "./imgs/编组 8.png";
import P2 from "./imgs/编组 7.png";
import P3 from "./imgs/编组 9.png";
import P4 from "./imgs/矩形备份 10.png";
import Left from "./imgs/left.png";
import Right from "./imgs/right.png";
import Qr from "../../assets/qr.jpg";
import RedBook from "../../assets/redbook.png";
import Ins from "../../assets/ins.jpeg";
import ALeft from "./imgs/a-left.png";
import Img1 from "./imgs/矩形 2.png";
import Img2 from "./imgs/矩形备份 11.png";

function V2P1() {
  const { t, i18n } = useTranslation();

  return (
    <div className="v2-p1">
      <video autoplay="autoPlay" muted>
        <source
          src="http://file.chellon.com/202201111529.mp4"
          type="video/mp4"
        />
      </video>
      <div className="c1">
        <div className="left"></div>
        <div className="right">
          <div className="t1">{t("v2-index.t1")}</div>
          <div className="t2">{t("v2-index.t2")}</div>
        </div>

        <div className="flow-c1">
          <div className="item">
            <div className="img">
              <img src={P1} alt="" />
            </div>
            <div className="label">{t("v2-index.l1")}</div>
          </div>

          <div className="item">
            <div className="img">
              <img src={P2} alt="" />
            </div>
            <div className="label">{t("v2-index.l2")}</div>
          </div>

          <div className="item">
            <div className="img">
              <img src={P3} alt="" />
            </div>
            <div className="label">{t("v2-index.l3")}</div>
          </div>

          <div className="item">
            <div className="img">
              <img src={P4} alt="" />
            </div>
            <div className="label">{t("v2-index.l4")}</div>
          </div>
        </div>

        <div className="flow-cur">
          <div className="left-m">
            <img src={Left} alt="" />
          </div>
          <div className="right-m">
            <img src={Right} alt="" />
          </div>
        </div>
      </div>
      <div className="c2">
        <div className="left"></div>
        <div className="right">
          <div className="t1">{t("v2-index.a-t-1")}</div>
          <div className="t2">{t("v2-index.a-t-2")}</div>
        </div>
        <div className="content">
          {[
            t("v2-index.a1"),
            t("v2-index.a2"),
            t("v2-index.a3"),
            t("v2-index.a4"),
            t("v2-index.a5"),
          ].map((t, i) => (
            <div className="item" key={i}>
              <p>{t} </p>
              <span className="num">0 {i + 1}</span>
            </div>
          ))}
        </div>
      </div>

      <div className="c3">
        <div className="qr-imgs">
          <div className="qr-img">
            <img src={RedBook} alt="" />
            <p>{i18n.language == "ja" ? "RED" : "小红书"}</p>
          </div>
          <div className="qr-img">
            <img src={Qr} alt="" />
            <p>{i18n.language == "ja" ? "公式アカウント" : "公众号"}</p>
          </div>
          <div className="qr-img">
            <img src={Ins} alt="" />
            <p>Instagram</p>
          </div>
        </div>
        <div className="arrow">
          <p>
            <img src={ALeft} alt="" />
            {t("v2-index.qr-d-1")}
          </p>
          <p>{t("v2-index.qr-d-2")}</p>
        </div>
      </div>

      <div className="c4">
        <div className="item">
          <div className="label-left">
            <h1>{t("v2-index.desc-t-1")}</h1>
            <h1>{t("v2-index.desc-t-2")}</h1>
            <div className="detail">{t("v2-index.load-more")}</div>
          </div>

          <div className="content-right">
            <div className="text">
              <p>{t("v2-index.desc-d-1")}</p>
              <p>{t("v2-index.desc-d-2")}</p>
            </div>

            <div className="img">
              <img src={Img1} alt="" />
            </div>
          </div>
        </div>
        <div className="item">
          <div className="content-left">
            <div className="img">
              <img src={Img2} alt="" />
            </div>
            <div className="text">
              <p>{t("v2-index.desc-d-3")}</p>
              <p>{t("v2-index.desc-d-4")}</p>
            </div>
          </div>

          <div className="label-right">
            <h1>{t("v2-index.desc-t-3")}</h1>
            <h1>{t("v2-index.desc-t-4")}</h1>
            <div className="detail">{t("v2-index.load-more")}</div>
          </div>
        </div>
      </div>
      <div style={{ height: 50 }}></div>
    </div>
  );
}

export default V2P1;
