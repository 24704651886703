import React from 'react';
import './Footer2.less';
import Phone from '../assets/phone.png'
import Local from '../assets/local.png'
import { useTranslation } from 'react-i18next'

function Footer2() {
  const { t } = useTranslation()
  return (
    <div className='footer2'>
      <div className="box_17 flex-col">
            <div className="group_13 flex-row justify-between">
              <div className="section_1 flex-row">
                <div className="image-text_9 flex-row justify-between">
                  <img
                    className="label_1"
                    src={
                      Phone
                    }
                  />
                  <span className="text-group_7">107-0052</span>
                </div>
              </div>
              <div className="section_2 flex-row">
                <div className="image-text_10 flex-row justify-between">
                  <img
                    className="label_2"
                    src={
                      Local
                    }
                  />
                  <span className="text-group_8">{t('address')}</span>
                </div>
              </div>
            </div>
            <div className="text-wrapper_10 flex-col">
              <span className="text_41">service&#64;proticcs.com</span>
            </div>
            <div className="text_42">
            {t('declaration')}
            </div>
            <div className="line"></div>
            <div className="text_43">
              <a href="https://beian.miit.gov.cn/">沪ICP备2021006765号-2</a>
              <span>&nbsp;|&nbsp;</span>
              <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010102007060">沪公网安备&nbsp;31010102007060号</a>
              </div>
          </div>
    </div>
  );
}

export default Footer2;
