import React from 'react';
import './ques.less'
function Ques(props) {
  const {num, title} = props
  return (
    <div>
       <div className="ques">
        <div className="num">
        <span>{num}</span>
        </div>
        <div className="title">{title}</div>
      </div>
    </div>
  );
}

export default Ques;
