import React from 'react';
import { useTranslation } from 'react-i18next'
import Img from './imgs/矩形 2 (2).png'
import Img2 from './imgs/scl1.png'
import Header from './component/header'
import Pm from './component/p-m'
import Diff from './component/diff'
import Qa from './component/qa'
import Chat, { Ans, Ques } from './component/chat';

function PScl() {
  const { t } = useTranslation()
  return (
    <div style={{backgroundColor: '#FAFAFA', overflow: 'hidden'}}>
      <Header t1={t("p-scl.title1")} t2={t("p-scl.title2")} pic={Img} />
      <Pm num="01" title={t('p-scl.pm-t')} pic={Img2} contents={[
        t('p-scl.pm-d-1'),
        t('p-scl.pm-d-2'),
        t('p-scl.pm-d-3')
      ]}/>
      <Diff />
      <Qa num="03" title={t('p-scl.qa-title')} qas={[
        {
          q: t('p-scl.qa-q-1'),
          a: [
            t('p-scl.qa-a-1.a1'),
            t('p-scl.qa-a-1.a2'),
            t('p-scl.qa-a-1.a3'),
            t('p-scl.qa-a-1.a4'),
          ]
        },
        {
          q: t('p-scl.qa-q-2'),
        },
        {
          noNum: true,
          q: t('p-scl.qa-q-3'),
          a: [
            t('p-scl.qa-a-3.a1'),
            t('p-scl.qa-a-3.a2'),
            t('p-scl.qa-a-3.a3'),
          ]
        },
        {
          noNum: true,
          q: t('p-scl.qa-q-4'),
          a: [
            t('p-scl.qa-a-4.a1'),
          ]
        },
        {
          noNum: true,
          q: t('p-scl.qa-q-5'),
          a: [
            t('p-scl.qa-a-5.a1'),
            t('p-scl.qa-a-5.a2'),
            t('p-scl.qa-a-5.a3'),
            t('p-scl.qa-a-5.a4'),
          ]
        },
        {
          noNum: true,
          q: t('p-scl.qa-q-6'),
          a: [
            t('p-scl.qa-a-6.a1'),
            t('p-scl.qa-a-6.a2'),
          ]
        },
        {
          noNum: true,
          q: t('p-scl.qa-q-11'),
          a: [
            t('p-scl.qa-a-11.a1'),
            t('p-scl.qa-a-11.a2'),
            t('p-scl.qa-a-11.a3'),
            t('p-scl.qa-a-11.a4'),
          ]
        },
        {
          noNum: true,
          q: t('p-scl.qa-q-7'),
          a: [
            t('p-scl.qa-a-7.a1'),
            t('p-scl.qa-a-7.a2'),
            t('p-scl.qa-a-7.a3'),
            t('p-scl.qa-a-7.a4'),
            t('p-scl.qa-a-7.a5'),
          ]
        },
        {
          noNum: true,
          q: t('p-scl.qa-q-8'),
          a: [
            t('p-scl.qa-a-8.a1'),
            t('p-scl.qa-a-8.a2'),
          ]
        },
        {
          noNum: true,
          q: t('p-scl.qa-q-9'),
          a: [
            t('p-scl.qa-a-9.a1'),
            t('p-scl.qa-a-9.a2'),
            t('p-scl.qa-a-9.a3'),
            t('p-scl.qa-a-9.a4'),
          ]
        },
        {
          noNum: true,
          q: t('p-scl.qa-q-10'),
          a: [
            t('p-scl.qa-a-10.a1'),
            t('p-scl.qa-a-10.a2'),
            t('p-scl.qa-a-10.a3'),
          ]
        },
      ]} />
      <Chat title={t('v2.chat-title')} list={[
        {
          type: Ques,
          text: t("p-scl.chat.c1") 
        },
        {
          type: Ans,
          text: t("p-scl.chat.c2") 
        },
        {
          type: Ques,
          text: t("p-scl.chat.c3") 
        },
        {
          type: Ans,
          text: t("p-scl.chat.c4") 
        },{
          type: Ques,
          text: t("p-scl.chat.c5") 
        },{
          type: Ans,
          text: t("p-scl.chat.c6") 
        },
      ]}/>
    </div>
  );
}

export default PScl;
