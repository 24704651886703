import e1 from '../assets/ed1.png'
import e2 from '../assets/ed2.png'
import product1 from '../assets/pl2.jpg'

const imgList = [
    product1
]

const zhData = {
    imgList,
    name: 'Carboless(有助于限制糖分吸收的有益微生物)',
    desc: '担心摄取碳水化合物发胖，却很喜欢米饭和面食的人接下来有好消息了！',
    productIntroduce: {
        title: '这个时候特别推荐！',
        descs: [
            '想吃米饭，面食，蛋糕和零食',
            '限制饮食的时候，碰到必须参加晚宴',
            '吃碳水化合物容易发胖的体质，却不想要限制饮食',
            '为了防止饮食限制之后的反弹',
            '有爱吃碳水化合物的另一半',
        ]
    },
    components: ['Carboless是一种能够减缓碳水化合物降解酶功能的产品。',
        '即便食用碳水化合物，瘦身也能很容易地实现！！', '(瘦身最大的敌人是在解放长期限制碳水化合物摄取的精神痛苦时产生的极端反弹)摄取适量的碳水化合物也可以保持高效的瘦身！可作为瘦身的强大后援。'],
    effect: [{
        title: 'Carboless阻断糖分吸收的机理',
        bgc: '#FAFAFA',
        titleItems: [
            {
                title: 'Carboless的主要成分是匙羹藤提取物(Gymnema sylvasta)，白豇豆提取物。',
                descs: ['这些成分中的每一个都能抑制碳水化合物的吸收，通过摄取这些成分就能让阻断糖分吸收成为可能。',
                    <div style={{ display: 'flex', justifyContent: "center", marginTop: 80, padding: '0 40px' }}>
                        <img src={e1} alt="" />
                    </div>
                ]
            },
        ],
    }, {
        title: 'Carboless有效成分：匙羹藤提取物(Gymnema sylvasta)白豇豆提取物',
        bgc: '#FAFAFA',
        titleItems: [
            {
                title: '匙羹藤提取物/通过摄取源自印度产的草药匙羹藤提取物，抑制糖分的吸收。',
                descs: []
            },
            {
                title: '白豇豆提取物/防止体内摄取过剩的卡路里转化为脂肪。',
                descs: ['维生素B群，矿物质生产能力，免疫平衡，花粉症，过敏，特应性皮炎改善，高血压，糖尿病，高脂血症均有改善作用']
            },
        ],
    }, {
        title: '造成肠漏综合症的最大原因是因为谷蛋白。',
        bgc: '#FAFAFA',
        titleItems: [
            {
                title: '肠漏综合症的症状多种多样，对腹泻便秘、腹痛、胀气、皮肤湿疹、荨麻疹、瘙痒、脱发、白发、肥胖、皮肤老化，皱纹色斑、发达障碍、自闭症、神经性焦虑症、抑郁症、统合失调症等都起到相当不错的改善作用。',
                descs: []
            },
        ],
    },],
    function: [
        {
            title: '使用方法',
            bgc: '#FFF',
            titleItems: [
                {
                    title: '1天２粒，温水服用。',
                    descs: [""]
                }
            ]
        },
        {
            title: '使用时的注意点',
            bgc: '#FAFAFA',
            titleItems: [
                {
                    title: '如果在服用中感到异常，请立即停止服用并与医师进行咨询。请确认原料成分，若对原料存在过敏，请不要进行服用。孕妇、哺乳期妇女请遵照医嘱确认是否可以进行服用。开封后，无论记载的有效期限如何，都请尽早进行服用。请放在儿童接触不到的地方进行保存。该商品的生产工厂还生产包含鸡蛋，牛奶，小麦，花生，虾，荞麦，螃蟹和大豆的产品。',
                    descs: []
                },
            ]
        },
        {
            title: '成分表',
            bgc: '#FFF',
            titleItems: [
                {
                    title: '匙羹藤提取物(印度制造)、难消化性糊精、白豇豆提取物、糊精/HPMC、硬脂酸Ca、二氧化硅微粒',
                    descs: []
                },
            ]
        },
        {
            title: '制造国',
            bgc: '#FFF',
            titleItems: [
                {
                    title: '日本',
                    descs: []
                },

            ]
        }
    ]
}

const jpData = {
    imgList,
    name: 'カーボレス(ダイエットサポート微生物)',
    desc: '炭水化物を食べたら太る、でもご飯もパスタも大好き！という方に朗報です。',
    productIntroduce: {
        title: 'こんなときにおすすめ！',
        descs: [
            '糖質は気になるご飯もパスタもケーキもおやつも食べたい',
            'ダイエット中なのに、どうしても食事会に参加しなくてはな らない',
            '炭水化物で太りやすい体質だけど食べるの我慢したくない',
            'ダイエット中、ダイエット後のリバウンド予防に',
            '炭水化物が大好きなあなたに',
        ]
    },
    components: ['カーボキャンセラーは炭水化物の分解酵素の機能を緩める効果があります。', 'だから糖質オフダイエットだって楽々叶えてしまう！！',
        `ロンガム菌がさらにダイエット相乗効果を発揮します。ダイエットの最大の敵は炭水化物を制限するため精神的に苦しいことと終了後のリバウンドです。 適度な炭水化物を接種しながら効率的に痩身を図る！ダイエットの協力サポーター。`
    ],
    effect: [{
        title: 'カーボキャンセラーの糖質カットメカニズム',
        bgc: '#FAFAFA',
        titleItems: [
            {
                title: 'カーボキャンセラーの主成分はギムネマ・シルベスタ、コレウスフォルスコリ、白インゲン豆抽出物。',
                descs: ['これらそれぞれの働きが糖質の吸収を抑制し、飲むだけで糖質オフを可能にします。', <div style={{ display: 'flex', justifyContent: "center", marginTop: 80, padding: '0 40px' }}>
                    <img src={e2} alt="" />
                </div>]
            },
        ],
    }, {
        title: 'Carboless有效成分：匙羹藤提取物(Gymnema sylvasta)白豇豆提取物',
        bgc: '#FAFAFA',
        titleItems: [
            {
                title: '匙羹藤提取物/通过摄取源自印度产的草药匙羹藤提取物，抑制糖分的吸收。',
                descs: []
            },
            {
                title: '白豇豆提取物/防止体内摄取过剩的卡路里转化为脂肪。',
                descs: ['维生素B群，矿物质生产能力，免疫平衡，花粉症，过敏，特应性皮炎改善，高血压，糖尿病，高脂血症均有改善作用']
            },
        ],
    }],
    function: [
        {
            title: '使用方法',
            bgc: '#FFF',
            titleItems: [
                {
                    title: '1日２粒を目安に水または白湯とともにお召し上がりください。',
                    descs: []
                },
            ]
        },
        {
            title: '使用上のご注意',
            bgc: '#FAFAFA',
            titleItems: [
                {
                    title: 'お体に異常を感じた場合は飲用を中止し医師にご相談下さい。原材料をご確認の上、食物アレルギーのある方はお召し上がりにならないで下さい。妊婦、授乳中およびお薬を服用中の方は、医師とご相談の上服用下さい。開封後はしっかりとチャックを閉め賞味期限に関わらず早めにお召し上がり下さい。乳幼児の手の届かないところに保管してください。本品製造工場では、卵、乳、小麦、落花生、 えび、そば、かに、大豆を含む製品も生産しています。',
                    descs: []
                },
            ]
        },
        {
            title: '全成分',
            bgc: '#FFF',
            titleItems: [
                {
                    title: `匙羹藤提取物(印度制造)、难消化性糊精、白豇豆提取物、糊精/HPMC、硬脂酸Ca、二氧化硅微粒`,
                    descs: []
                },
            ]
        },
        {
            title: '製造国',
            bgc: '#FFF',
            titleItems: [
                {
                    title: `日本`,
                    descs: []
                },
            ]
        },
    ]
}

const enData = {
    imgList, "name": "Carboless(Diet support microorganisms)", "desc": "If you eat carbohydrates, you will get fat, but I love rice and pasta!Good news for those who say.", "components": ["Carbo cancellers have the effect of loosening the function of carbohydrate degraded enzymes.", "So even a carbohydrate off -diet will make it easy!!", "Longham bacteria also have a diet synergistic effect.The biggest enemy of the diet is mentally difficult and rebound after the end to restrict carbohydrates.Efficiently slimming while inoculation of moderate carbohydrates!Diet cooperation supporter."], "productIntroduce": { "title": "Recommended in such a case!", "descs": ["Even though I'm on a diet, I have to participate in the dinner party", "I want to eat rice, pasta, cake and snacks", "For rebound prevention during dieting", "It is a carbohydrate that is easy to get fat, but I do not want to endure eating", "For you who love carbohydrates"] }, "effect": [{
        "bgc": "#FAFAFA", "title": "Carbo canceller carbohydrate cut mechanism", "titleItems": [{
            "descs": ["Each of these works suppresses the absorption of carbohydrates, and simply drinks sugar.", <div style={{ display: 'flex', justifyContent: "center", marginTop: 80, padding: '0 40px' }}>
                <img src={e2} alt="" />
            </div>], "title": "The main components of the carbo canceller are Gimnema Sylvesta, Coreus Forskoli, and White Ingen Bean extract."
        }]
    }, { "bgc": "#FAFAFA", "title": "Carboless effective ingredients: spoonful vine sylvasta extract", "titleItems": [{ "descs": [], "title": "Tablet Tengtu extract/By ingestion of Herbal spoon derived from India, the vine extraction is suppressed to inhibit the absorption of sugar." }, { "title": "White cowpea extract/prevent the body's in -body from being transformed into fat.", "descs": ["Vitamin B group, mineral production capacity, immune balance, pollen, allergies, improving dermatitis, hypertension, diabetes, and hyperlipidemia"] }] }], "function": [{ "bgc": "#FFF", "title": "Instructions", "titleItems": [{ "descs": [], "title": "Please enjoy two tablets a day with water or hot water." }] }, { "bgc": "#FAFAFA", "title": "Precautions for use", "titleItems": [{ "descs": [], "title": "If you feel abnormal in your body, stop drinking and consult your doctor.If you have food allergies, please do not enjoy the raw materials.If you are pregnant, breastfeeding or taking medicine, please consult with your doctor.After opening, close the chuck firmly and enjoy it early regardless of the expiration date.Please keep it in a place where infants are out of reach.This product manufacturing plant also produces products that contain eggs, milk, wheat, peanuts, shrimp, buckwheat, and soybeans." }] }, { "bgc": "#FFF", "title": "Manufacturer", "titleItems": [{ "descs": [], "title": "Japan" }] }, { "bgc": "#FFF", "titleItems": [{ "descs": [], "title": "Tablet Team extract (manufactured by India), indigestion of paste essence, white cowpea extract, paste/hpmc, lipidic acid CA, silicon dioxide particles" }], "title": "Ingredient" }]
}

export default {
    zhData, jpData, enData
}