import product1 from '../assets/product1.png'
import product2 from '../assets/product2.jpeg'
import e1 from '../assets/e1.jpg'
import e2 from '../assets/e2.jpg'

import e3 from '../assets/73f4812a89df60be.jpeg'
import e4 from '../assets/07146927274ac68a.jpeg'
import e5 from '../assets/63a05ad8972c7b68.jpeg'
import e6 from '../assets/168f33620889bce2.jpeg'
import e7 from '../assets/9d5ea4ed65ff6f2b.jpeg'
import e8 from '../assets/WechatIMG2912.jpeg'


const imgList = [
    product1, e8, e3, e5, e6
]

const zhData = {
    imgList,
    name: 'magam³',
    desc: '使用 Magma菌 和 嗜酸乳杆菌 相配合的营养品',
    productIntroduce: {
        title: '什么是Magma菌？',
        descs: [
            '距今约5000万年前，在印度次大陆与亚洲大陆交界处的特提斯海(古海洋), 因两大洲的碰撞致使海洋中的浮游生物、土壤、矿物质等被压缩，(该地区现位于喜马拉雅山脉3000至5000米处)沉积形成了Magma菌。',
            'Magma菌的使用可以追溯到公元前3000年的印度文明，在阿育吠陀医学中被认为是Rasayana的存在。',
        ]
    },
    components: ['生息于远古时代的动植物、鱼类、藻类等通过长达数千年的堆积，以及微生物长年累月的分解与发酵后形成最终产物，并从中提取出的物质。',
        // '喜来芝(Shilajit)提取物粉末(喜来芝(Shilajit)提取物、糊精)(印度制造)、糊精、乳酸菌末(淀粉、生菌干燥原末)／HPMC、维生素C、硬脂酸Ca、L-亮氨酸、 L-缬氨酸、L-异亮氨酸、维生素E、烟酸、胶凝糖胶、泛酸钙、维生素B1、维生素B6、维生素B2、维生素A、二酸化硅、叶酸、维生素D、维生素B12(一部分含大豆成分)'
    ],
    effect: [{
        title: '可期待的效果 (Magma菌)',
        bgc: '#FAFAFA',
        titleItems: [
            {
                title: '通过提高睾丸激素和一氧化氮的总浓度和游离浓度，使个人精力充沛',
                descs: []
            },
            {
                title: '提高ATP等级、促进持久力和综合体能的提高',
                descs: []
            },
            {
                title: '改善因中度关节炎引起的全身疼痛，治疗后的手脚疼痛',
                descs: []
            },
            {
                title: '减轻身体运动后的疼痛',
                descs: []
            },
            {
                title: '使人体骨骼肌中存在的类固醇生成基因上调(正申请专利)',
                descs: []
            }, {
                title: '使控制骨骼肌编码的基因上调，促进合成的胶原蛋白和相关细胞外基质蛋白数量的加倍增长(正申请专利)',
                descs: []
            }, {
                title: '抑制炎症生物标志物(hsCRP)50％以上',
                descs: [
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 80, padding: '0 40px' }}>
                        <img src={e1} alt="" style={{ width: 320, height: 226 }} />
                        <img src={e2} alt="" style={{ width: 320, height: 226 }} />
                    </div>
                ]
            },
        ],
    }],
    function: [
        {
            title: '乳酸菌(L. acidophilus)',
            bgc: '#FFF',
            titleItems: [
                {
                    title: '嗜酸乳杆菌有增肌效果',
                    descs: []
                },
                {
                    title: '摄入嗜酸乳杆菌会增加肠道中益生菌的数量，改善肠道环境及覆盖肠道表面细胞层(上皮)的状态。于此同时，提高消化食物的吸收率，从而增加体重。',
                    descs: []
                }
            ]
        },
        {
            title: 'magam³(magam³有效成分-BCAA的功效)',
            bgc: '#FAFAFA',
            titleItems: [
                {
                    title: '服用BCAA后在进行运动时可更为安心，运动过程中BCAA可作为肌肉能量来源并可以保持这样的状态。',
                    descs: []
                },
                {
                    title: '在进行耐力运动时，人体会利用体内的糖、脂肪以及血液中的BCAA转化为自身的能量。',
                    descs: []
                },
                {
                    title: '为了使增肌训练更为有效，提高血液中氨基酸的浓度是很有必要的。',
                    descs: []
                },
                {
                    title: '在必需氨基酸中，BCAA可更有效的促进肌肉合成。',
                    descs: []
                },
            ]
        },
        {
            title: 'magam³ (magam³有效成分-Magma菌的功效)',
            bgc: '#FFF',
            titleItems: [
                {
                    title: '预防新型冠状病毒感染',
                    descs: ['ARCHAEA的原料中含有Bacteroides dorei•B. thetaiotaomicron•B. 和 ovatus 。', '•新冠肺炎患者粪便中存在新冠病毒的量与Bacteroides dorei•B. thetaiotaomicron•B.massiliensis•B. 和 ovatus 的相对量呈反向递增。',
                        '这些Bacteroides种会抑制肠道上皮细胞「Ace2」的出现。', '(Ace2*血管紧张素转化酶2*：是新冠病毒的受体)', '•B. thetaiotaomicron 对化学诱导的肠炎(克罗恩病实验模型)具有抗炎作用。']
                },
                {
                    title: '改善女性荷尔蒙失衡，更年期障碍，骨质酥松症',
                    descs: ['magam³中含有很多有助于「产生短链脂肪酸(甲酸、乙酸 等)」的菌。', '通过口服「magam³」形式可产生「多酚」，「多酚」被认为具有「类似女性荷尔蒙的作用」，并提供「预防骨质酥松症及缓解更年期障碍」等效果，并且可以期待其对于「II型糖尿病」和「更年期相关疾病」的效果。']
                },
            ]
        },
        {
            title: '使用方法',
            bgc: '#FFF',
            titleItems: [
                {
                    title: '1天1粒，温水服用。',
                    descs: []
                },
            ]
        },
        {
            title: '使用时的注意点',
            bgc: '#FFF',
            titleItems: [
                {
                    title: '如果在服用中感到异常，请立即停止服用并与医师进行咨询。请确认原料成分，若 对原料存在过敏，请不要进行服用。',
                    descs: []
                },
                {
                    title: '孕妇，哺乳期妇女请遵照医嘱确认是否可以 进行服用。',
                    descs: []
                },
                {
                    title: '开封后，无论记载的赏味期限如何，都请尽早进行服用。请放在儿童接触不到的地方进行保存。',
                    descs: []
                },
                {
                    title: '该商品的生产工厂还生产包含鸡蛋，牛奶，小麦，花生， 虾，荞麦，螃蟹和大豆的产品。',
                    descs: []
                },
            ]
        }
    ],
    newEffect: {
        title: '源自火山恩惠\n蕴含500余种存活超5000万年的熔岩活性生物群',
        items: [
            {
                num: '01.',
                title: '促使人体骨骼肌中存在的类固醇生成基因的表现上调up-regulation（正申请专利）',
                details: [
                    '- 帮助骨骼肌的修复和增长'
                ]
            },
            {
                num: '02.',
                title: '促使控制骨骼肌基因编码的表现上调 up-regulation（正申请专利）',
                details: [
                    '- 促进胶原蛋白合成以及相关细胞外基质蛋白数量的加倍增长'
                ]
            },
            {
                num: '03.',
                title: '提高人体ATP（三磷酸腺苷）的储存和传递',
                details: [
                    '- 改善机体代谢，促进持久力和综合体能提高'
                ]
            },
            {
                num: '04.',
                title: '提高血液中BCAA氨基酸浓度',
                details: [
                    '- 促进肌肉合成及运动后疲劳的恢复'
                ]
            },
            {
                num: '05.',
                title: '提高血清睾酮及体内一氧化氮总浓度和游离浓度',
                details: [
                    '- 提高男性精子的活力和数量，更加精力充沛'
                ]
            },
        ]
    },
    newDetail: {
        title: '点燃细胞活力\n激发内在潜力',
        kvs: [
            {
                key: '产品名：',
                value: 'magam3玛伽姆天然复合活菌胶'
            },
            {
                key: '规格：',
                value: '30日份（1日1粒）'
            },
            {
                key: '原产地：',
                value: '日本'
            },
            {
                key: '储存方法：',
                value: '阴凉干燥处储存'
            },
            {
                key: '服用方法：',
                value: '1天1粒，温水服用'
            },
            {
                key: '注意事项：',
                value: '孕妇、哺乳期妇女、身体不适者、易过敏者，在食用本品前，请咨询医生。 开封后，请尽早服用。请放在儿童接触不到的地方进行保存。本品不能代替药品服用。'
            },
            {
                key: '成分表：',
                value: '喜来芝(Shilajit)提取物末（喜来芝(Shilajit)提取物、糊精）（印度制造）、糊 精、乳酸菌末（淀粉、生菌干燥原末）／HPMC、维生素C、硬脂酸Ca、L-亮氨酸、 L-缬氨酸、L-异亮氨酸、维生素E、烟酸、胶凝糖胶、泛酸钙、维生素B1、维生素 B6、维生素B2、维生素A、二酸化硅、叶酸、维生素D、维生素B12、（一部分含大豆成分）'
            },

        ]
    }
}

const jpData = {
    imgList,
    name: 'magam³',
    desc: 'マグマ菌と乳酸菌アシドフィリス配合サプリメント',
    productIntroduce: {
        title: '商品情報',
        descs: [
            '太古の昔に生息していた動植物、魚介類、藻類などが堆積し、それが数千年もの長い年月を掛けて微生物によって分解と発酵を繰り返してできた最終生成物から抽出された物質。',
            'シラジットエキス末(製造国インド)、デキストリン、乳酸菌末(デンプン、生菌乾燥原末)／HPMC、ビタミンC、ステアリン酸Ca、L-レシチン、L-バリン、L-イソロイシン、ビタミンE、煙酸、膠結グリセリン、パントせテン酸カルシウム、ビタミンB 1、ビタミンB 6 A、ビタミンB 2 A二酸化シリコン、葉酸、ビタミンD、ビタミンB 12、(一部は大豆成分を含む)',
        ]
    },
    components: ['·エネルギー源を供給して、はつらつさを保つ',
    '·イキイキと元気なカラダをアシスト',
    '·内側からのやる気をバックアップ',
    '·全身を整え、笑顔が増え、活力ある毎日に'
    ],
    effect: [{
        title: '期待される効果 (マグマ菌)',
        bgc: '#FAFAFA',
        titleItems: [
            {
                title: 'テストステロンと一酸化窒素の総濃度や遊離濃度を上げることで、精力を増進',
                descs: []
            },
            {
                title: 'ATPレベルを上げることで、持久力や総合的な体力を向上',
                descs: []
            },
            {
                title: '中度の関節炎を患うイヌにおいて、全身の痛み、手足の処置後の痛み、',
                descs: []
            },
            {
                title: '身体運動後の痛みを軽減',
                descs: []
            },
            {
                title: 'ヒト骨格筋に存在するステロイド産生遺伝子を上方制御(特許出願中)',
                descs: []
            }, {
                title: '骨格筋のコーディングを司る遺伝子を上方制御し、コラーゲンや関連する細胞 外マトリックスタンパク質の合成量を倍増(特許出願中)',
                descs: []
            }, {
                title: '炎症バイオマーカー(hsCRP)を50％以上抑制',
                descs: [
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 80, padding: '0 40px' }}>
                        <img src={e1} alt="" style={{ width: 320, height: 226 }} />
                        <img src={e2} alt="" style={{ width: 320, height: 226 }} />
                    </div>
                ]
            },
        ],
    }],
    function: [
        {
            title: '乳酸菌(L. acidophilus)',
            bgc: '#FFF',
            titleItems: [
                {
                    title: 'アシドフィリス菌は筋肉増大効果',
                    descs: []
                },
                {
                    title: 'L. acidophilusを摂取すると腸内の善玉菌を増加させて',
                    descs: ['腸内環境を改善し腸の表面を覆う細胞層(上皮)の状 態を改善する。その結果、消化産物の吸収効率が高まるため体重を増加させる。']
                }
            ]
        },
        {
            title: 'magam³有効成分BCAAの効能効果',
            bgc: '#FAFAFA',
            titleItems: [
                {
                    title: 'BCAAは運動時に筋肉のエネルギー源となってコンディションをサポートしてくれる運動の心強い味方です。',
                    descs: []
                },
                {
                    title: '持久運動を行うとカラダは糖質や脂肪、血液中のBCAAを使ってエネルギーを生み出しています。',
                    descs: []
                },
                {
                    title: '筋肉をつけるための筋トレをより効果的にするには、血中のアミノ酸濃度を高めておく必要があります。',
                    descs: []
                },
                {
                    title: 'BCAAの摂取は必須アミノ酸の中でも筋合成を高めるために有効です。',
                    descs: []
                },
            ]
        },
        {
            title: 'magam³有効成分マグマ菌の効能効果',
            bgc: '#FFF',
            titleItems: [
                {
                    title: '新型コロナウイルス感染予防',
                    descs: ['magam³の原料にはBacteroides dorei・B. thetaiotaomicron・B. ovatusが含まれています。', '•新型肺炎患者糞便中のSARS-CoV-2量は、Bacteroides dorei・B. thetaiotaomicron・B. massiliensis・B. ovatusの相対量と逆相関しています。',
                        '•これらのバクテロイデス種は腸上皮細胞のAce2の発現を抑制します。', '(Ace2とは、新型コロナウイルスをキャッチするレセプター)', '•B. thetaiotaomicronは、化学誘発腸炎(クローン病の実験モデル)に対して抗炎症作用を発揮します。']
                },
                {
                    title: '女性ホルモンバランス改善、更年期障害、骨粗鬆症改善',
                    descs: ['magam³の中に、短鎖脂肪酸(ギ酸、酢酸など)の産生する細菌を多数発見。', '内服する事により、ポリフェノールを産生し、「女性ホルモン様の作用 をする」、「骨粗鬆症の予防や更年期障害を軽減する」、「脂質代謝の 改善などに有効である」などと言われ、肥満、II型糖尿病、更年期障害 ののぼせなどに対しては、ヒトでの有効性が期待できます。']
                },
            ]
        },
        {
            title: '使用方法',
            bgc: '#FFF',
            titleItems: [
                {
                    title: '1日２粒を目安に水または白湯とともにお召し上がりください。',
                    descs: []
                }
            ]
        },
        {
            title: '使用上のご注意',
            bgc: '#FFF',
            titleItems: [
                {
                    title: 'お体に異常を感じた場合は飲用を中止し医師にご相談下さい。',
                    descs: []
                },
                {
                    title: '原材料をご確認の上、食物アレルギーのある方はお召し上がりにならないで下さい。',
                    descs: []
                },
                {
                    title: '妊婦、授乳中およびお薬を服用中の方は、医師とご相談の上服用下さい。',
                    descs: []
                },
                {
                    title: '開封後はしっかりと チャックを閉め賞味期限に関わらず早めにお召し上がり下さい。',
                    descs: []
                },
                {
                    title: '乳幼児の手の届かないところに保管してください。',
                    descs: []
                },
                {
                    title: '本品製造工場では、卵、乳、小麦、落花生、 えび、そば、かに、大豆を含む製品も生産しています。',
                    descs: []
                },
                {
                    title: '工場の管理体制からこれらが混入することはありません。',
                    descs: []
                },
            ]
        }
    ]
}

const enData = {
    imgList,
    "name": "magam³",
    "desc": "Supplements containing magma bacteria and lactic acid bacteria acid philis",
    "effect": [{
        "bgc": "#FAFAFA", "title": "Expected effects (magma bacteria)",
        "titleItems": [{
            "descs": [],
            "title": "Increase energy by increasing the total concentration and free density of testosterone and nitric oxide"
        }, { "descs": [], "title": "In dogs suffering from moderate arthritis, pain in the whole body, pain after limbs," }, { "descs": [], "title": "Reduces pain after physical exercise" }, { "descs": [], "title": "Upon control of steroid production gene in human skeletal muscle (patent application)" }, { "descs": [], "title": "Upon control of the coding of skeletal muscle coding, doubling the amount of synthesis of collagen and relevant extracellular matrick stampak (patent application)" }, { "descs": [], "title": "By increasing the ATP level, improving endurance and comprehensive physical strength" },
        {
            "descs": [<div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 80, padding: '0 40px' }}>
                <img src={e1} alt="" style={{ width: 320, height: 226 }} />
                <img src={e2} alt="" style={{ width: 320, height: 226 }} />
            </div>],
            "title": "Inflammation biomarker (HSCRP) is 50 % or more"
        }]
    }],
    "productIntroduce": {
        "title": "What is a magma bacterium?",
        "descs": ["The magma bacterium was about 50 million years ago, between the Indian Asa continent and the Asian continent.Plankton, marine soil, minerals, etc., which lived in the sea due to the collision of the two continents, are compressed, and the area is located 3000-5000 m in the current Himalayas, and the magma bacteria are deposited there.。", "The use of magma bacteria dates back to the Indus civilization of 3000 BC, and in Ayurveda, it is said to be Rasayana."]
    },
    "components": ["A substance extracted from the final product that has been deposited by animal and fauna, seafood, and algae that have lived in ancient times, which have been repeatedly decomposed and fermented by microorganisms over several years.", "Sylazit extract (made in India), dextrin, lactic acid bacteria (starch, raw bacteria drying raw end) / HPMC, vitamin C, stearate CA, L-lecitin, L-baline, L-isoleysin, vitamin E, smoke acid,Glycerin, calcium pantutinate, vitamin B 6 A, vitamin B 2 A silicon, folic acid, vitamin D, vitamin B 12, (some contain soy ingredients)."], "function": [{
        "bgc": "#FAFAFA", "title": "Magam³ Active ingredient BCAA's effectiveness",
        "titleItems": [{ "descs": [], "title": "When endurance exercise, the body uses sugar, fat, and BCAA in the blood to create energy." }, { "descs": [], "title": "To make muscle training more effective, you need to increase the amino acid concentration in the blood." }, { "descs": [], "title": "BCAA intake is effective for enhancing muscle combination among essential amino acids." },
         { "descs": [], "title": "BCAA is an encouraging ally of exercise that supports conditions as a source of muscle energy during exercise." }]
    }, { "bgc": "#FFF", "title": "Instructions", "titleItems": [{ "descs": [], "title": "Please enjoy two tablets a day with water or hot water." }] }, {
        "bgc": "#FFF", "title": "Precautions for use", "titleItems": [{ "descs": [], "title": "If you have food allergies, please do not enjoy the raw materials." }, { "descs": [], "title": "If you are pregnant, breastfeeding or taking medicine, please consult with your doctor." },
        { "descs": [], "title": "After opening, close the chuck firmly and enjoy it early regardless of the expiration date." }, { "descs": [], "title": "Please keep it in a place where infants are out of reach." }, { "descs": [], "title": "If you feel abnormal in your body, stop drinking and consult your doctor." }, 
        { "descs": [], "title": "These are not mixed from the factory management system." }, { "descs": [], "title": "This product manufacturing plant also produces products that contain eggs, milk, wheat, peanuts, shrimp, buckwheat, and soybeans." }]
    }, {
        "bgc": "#FFF", "title": "Lactic acid bacteria (L. acidophilus)", "titleItems": [{ "descs": [], "title": "Acidfilis bacteria are muscle enlargement effects" }, {
            "title": "Ingesting l. acidophilus increases good bacteria in the intestine",
            "descs": ["Improves the intestinal environment and improves the shape of the cell layer (epithelial) that covers the intestinal surface.As a result, gain weight because the absorption efficiency of digestive products is increased."]
        }]
    }, {
        "bgc": "#FFF", "title": "Magam³ Actual ingredient magma effect effect", "titleItems": [{ "title": "Female hormonal balance improvement, menopause, osteoporosis improvement", "descs": ["Many bacteria produced in short -chain fatty acids (such as gin acids, acetic acid, etc.) were found in Magam³.", "Obesity, such as producing polyphenols by taking in -house, \"acts as a female hormone\", \"prevents osteoporosis, reduces menopause\", and is effective in improving lipid metabolism.Human effectiveness can be expected for II diabetes and menopause."] }, {
            "title": "New colon virus infection prevention",
            "descs": ["The raw material of Magam³ contains Bacteroids Dorei, B. THETAIOTAOMICRON, B. OVATUS.", "• The amount of SARS-COV-2 in the new pneumonia patient is inverted with the relative amount of Bacteroids Dorei, B. THETAIOTAOMICRON, B. MASSILIENSIS, B. OVATUS.", "• These bacteroids species suppress the expression of Ace2 of intestinal endothelial cells.", "• B. THETAIOTAOMICRON has an anti -inflammatory effect on chemical -induced epitis (experimental model for clone disease).", "(Ace2 is a receptor that catches the new colon virus)"]
        }]
    }]
}

export default {
    zhData, jpData, enData
}