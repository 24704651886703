import React from 'react';
import Ques from './ques';
import './func.less'

function Func(props) {
  const { num , title, funcs} = props
  return (
    <div className='v2-func'>
      <Ques num={num} title={title} />
      <div className="funcs-b">
          {
            funcs.map((val, index)=>{
              return <div className="item">
                <div className="num">0{index+1}</div>
                <div className="title">{val.title}</div>
                <div className="content">{val.detail}</div>
              </div>
            })
          }
      </div>
    </div>
  );
}

export default Func;
