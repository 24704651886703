import React from "react";
import "./header.less";
import { useTranslation } from "react-i18next";
function Header(props) {
  const { t } = useTranslation();
  const { t1, t2, pic } = props;
  return (
    <div className="v2-header">
      <div className="head">
        <div className="left-h">
          <h1>About</h1>
          <p>
            <span className="p1">{t1}</span>
            <span className="p2">{t2}</span>
          </p>
        </div>
        <div className="right-h">
          <img src={pic} alt="" />
        </div>
      </div>
      <div className="stups">
        <div className="stup">
          {t("v2.about")}
          {t1}
        </div>
        <div className="stup">{t("v2.process")}</div>
        <div className="stup">{t("v2.ques")}</div>
      </div>
      <h1 className="title">
        {t("v2.about")}
        {t1}
      </h1>
    </div>
  );
}

export default Header;
