import React from 'react';
import V2p1 from './v2-p1';
import V2nk from './p-nk';
import V2sc from './p-sc';
import V2prp from './prp';
import V2scl from './p-scl';

const mapping = {
  'p1': <V2p1 />,
  'pnk': <V2nk />,
  'psc': <V2sc />,
  'prp': <V2prp />,
  'scl': <V2scl />
}

function V2(props) {
  const { id } = props.match.params
  return (
    <div>
      {
        mapping[id] ? mapping[id] : null
      }
    </div>
  );
}

export default V2;
