import React from 'react';
import './qa.less'
import Ques from './ques';

function Qa(props) {
  const { num, title, qas } = props
  return (
    <div className='v2-qa'>
      <Ques num={num} title={title} />
      <div className="qas">
        {
          qas.map((val, index)=>{
            return <div className="qa">
              <div className="title">
                {val.noNum ?  <span className="num">&nbsp;&nbsp;&nbsp;&nbsp;</span>: <span className="num">
                  {index > 8 ? '':'0'}{index+1}
                </span>}
                <span className="text">
                  {val.q}
                </span>
              </div>
              {val.a &&<div className="content">
                {Array.isArray(val.a) ? val.a.map((text, i)=>{
                  return <p key={i}><span className='dot'>{text}</span></p>
                })  :val.a}
              </div>}
            </div>
          })
        }
      </div>
    </div>
  );
}

export default Qa;
