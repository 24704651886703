import React from 'react'
import './Professor.less'
import Pd4 from '../assets/pd4.jpg'
import { useTranslation } from 'react-i18next'


export default props => {
    const { t, i18n } = useTranslation()
    return <div className="professor">
        <div className="professor-desc">
            <img src={Pd4} alt="" className="head-img" />
            <h1>{t('pro-name')}</h1>
            <p>{t('gra')}</p>
            <p>{t('collage')}</p>
            <p>{t('now')}</p>
            <p>{t('position')}</p>
        </div>
        <div className="professor-detail">
            <p>
                {t('professor1')}
            </p>
            <p>
                {t('professor2')}
            </p>
            <p>
                {t('professor3')}
            </p>
            <p>
                {t('professor4')}
            </p>
            <p>
                {t('professor5')}
            </p>
        </div>
    </div>
}