import React from "react";
import { withRouter } from "react-router-dom";
import "./Header.less";
import { useTranslation } from "react-i18next";
import Logo from "../assets/logo.svg";

export default withRouter((props) => {
  let { t, i18n } = useTranslation();

  const mapping = {
    ja: "日本語",
    zh: "中文",
    en: "English",
  };

  const { menus } = props;
  const v2 = ['pnk', 'psc', 'prp', 'scl']
  return (
    <div className="header-container">
      <div className="header">
        <div
          className="logo"
          onClick={() =>
            (window.location.href = "//" + window.location.host + "/v2/p1")
          }
        >
          {/* PROTICCS
            <span>TM</span> */}
          <img src={Logo} alt="" style={{ width: 111, height: 30 }} />
        </div>
        <div className="menu">
          <div className="menu-item">
            <a
              onClick={() => {
                window.location.href = '//' + window.location.host + "/v2/p1"
              }}
            >
              {t('v2.title')}
            </a>
            <div className="menu-item-childs">
              {
                Array(4).fill(0).map((_, index)=>{
                    return <div className="menu-item-child" onClick={()=>{
                      window.location.href = '//' + window.location.host+ "/v2/" + v2[index]
                    }}>
                        {t(`v2.title${(index+1)}`)}
                    </div>
                })
              }
            </div>
          </div>
          {menus.map((val, index) => {
            if(index<1)return
            return (
              <div className="menu-item" key={index}>
                <a
                  onClick={() => {
                    window.location.href =
                      "//" + window.location.host + val.path;
                  }}
                >
                  {val.title}
                </a>
              </div>
            );
          })}
        </div>
        <div className="select-locales">
          {mapping[i18n.language]} <span></span>
          <div className="locals">
            <div
              className="locale"
              onClick={() => {
                i18n.changeLanguage("zh");
                localStorage.setItem("lang", "zh");
              }}
            >
              中文
            </div>
            <div
              className="locale"
              onClick={() => {
                i18n.changeLanguage("ja");
                localStorage.setItem("lang", "ja");
              }}
            >
              日本語
            </div>
            <div
              className="locale"
              onClick={() => {
                i18n.changeLanguage("en");
                localStorage.setItem("lang", "en");
              }}
            >
              English
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
