import React, { useState, useEffect } from 'react'

import './Detail.less'
import { useTranslation } from 'react-i18next'
import Data1 from './Detail1'
import Data2 from './Detail2'
import Data3 from './Detail3'
import Data4 from './Detail4'
import Data5 from './Detail5'
import Data6 from './Detail6'
import D1 from './d1'
import axios from 'axios'
import { isArray } from '@craco/craco/lib/utils'


async function t(text) {
    if (!text) {
        return text
    }
    const resp = await axios.get('http://localhost:8066/t?t=' + text)
    return resp.data
}

async function tran(data) {
    const dts = {}
    if (Object.keys(data).indexOf('props') > -1) return 'reactComp'
    const b = Object.keys(data).map(async k => {
        const v = data[k]
        if (k === 'bgc' || k === 'name') {
            dts[k] = v
            return
        }
        if (k === 'imgList') {
            dts[k] = []
            return
        }

        if (isArray(v)) {
            const arr = []
            const b = v.map(async val => {
                if (typeof (val) === 'object') {
                    const obj = await tran(val)
                    arr.push(obj)
                } else {
                    const res = await t(val)
                    arr.push(res)
                }
            })
            // console.log(b)
            if (b.length > 0) {
                await Promise.all(b)
            }
            dts[k] = arr
            return
        }
        if (v && typeof (v) === 'object') {
            dts[k] = await tran(v)
            return
        }

        if (typeof (v) === 'string') {
            const res = await t(v)
            dts[k] = res
            return
        }

        if (!v) {
            console.log(k)
            return
        }

        if (k === 'descs') {
            console.log('descs', isArray(v))
            return
        }
    })
    // console.log(b)
    if (b && b.length > 0) {
        await Promise.all(b)
    }
    return dts
}


export default props => {
    const { id, lang } = props.match.params
    const [index, setIndex] = useState(0)
    const [tab, setTab] = useState(0)

    const products = {
        '1': Data1,
        '2': Data2,
        '3': Data3,
        '4': Data4,
        '5': Data5,
        '6': Data6,
    }

    const Data = products[id]

    const { t, i18n } = useTranslation()

    const mapping = {
        'ja': Data.jpData,
        'zh': Data.zhData,
        'en': Data.enData,
    }

    useEffect(async () => {
        // const a = await tran(Data6.jpData)
        // console.log(a)
        // console.log(JSON.stringify(a))
    }, [])

    const isNew = id === '1' && i18n.language === 'zh'

    const data = mapping[i18n.language]
    return <div className="detail">
        <div className="big-img">
            <img src={data['imgList'][index]} className="detail-img" alt="" />
            <h1 className="name">{data['name']}</h1>
            <p className="desc">{data['desc']}</p>
            <div className="img-list">
                {data['imgList'].map((val, i) => {
                    return <div className={i === index ? 'img-item active' : 'img-item'} onClick={() => setIndex(i)} key={i}>
                        <img src={val} alt="" />
                    </div>
                })}
            </div>
        </div>
        <div className="product-introduce">
            <h1 className="title">{data['productIntroduce']['title']}</h1>
            <div className="descs">
                {data['productIntroduce']['descs'].map((item, i) => {
                    return <p key={i}>{item}</p>
                })}
            </div>
        </div>
        {i18n.language !== 'ja' && <div className="product-component">
            <h1 className="title">{ t('component')}</h1> 
            
             <div className="descs">
                {data['components'].map((item, i) => {
                    return <p key={i}>{item}</p>
                })}
            </div>
            <div className="tabs">
                <div className={tab === 0 ? 'tab tab-active' : 'tab'} onClick={() => setTab(0)}>{!isNew ? t('effect') : '产品详情'}</div>
                <div className={tab === 1 ? 'tab tab-active' : 'tab'} onClick={() => setTab(1)}>{!isNew ? t('function') : '主要功效'}</div>
            </div>
        </div>}

        {
                i18n.language === 'ja' && id === '1' && <React.Fragment>
                    <D1 />
                </React.Fragment>
        }

        {
            i18n.language !== 'ja' && !isNew && tab === 0 && <>
                {
                    data['effect'].map((item, i) => {
                        return <div className="product-introduce-detail" style={{ background: i % 2 ? '#fff' : '#FAFAFA' }} key={i}>
                            <h1 className="title">{item.title}</h1>
                            {item.titleItems.map((val, j) => {
                                return <React.Fragment key={j}><div className="title-item" key={j}>
                                    <span>·</span>{val.title}
                                </div>
                                    {val.descs.map((desc, k) => {
                                        return <p key={k}>{desc}</p>
                                    })}
                                </React.Fragment>
                            })}
                        </div>
                    })
                }

            </>
        }

        {i18n.language !== 'ja' && !isNew && tab === 1 && <>
            {data['function'].map((item, i) => {
                return <div className="product-introduce-detail" style={{ background: i % 2 ? '#fff' : '#FAFAFA' }} key={i}>
                    <h1 className="title">{item.title}</h1>
                    {item.titleItems.map((val, j) => {
                        return <React.Fragment key={j}><div className="title-item" key={j}>
                            <span>·</span>{val.title}
                        </div>
                            {val.descs.map((desc, k) => {
                                return <p key={k}>{desc}</p>
                            })}
                        </React.Fragment>
                    })}
                </div>
            })}
        </>}
        {
            i18n.language !== 'ja' && isNew && tab === 0 && <>
                <div className="item-title">{data.newDetail.title}</div>
                <div className="item-k-v">
                    {
                        data.newDetail.kvs.map((item, index) => {
                            return <div className="item" key={index}><span className="key">{item.key}</span><span className="value">{item.value}</span></div>
                        })
                    }
                </div>
            </>
        }
        {
            i18n.language !== 'ja' && isNew && tab === 1 && <>
                <div className="item-title">{data.newEffect.title}</div>
                <div className="item-effect">
                    {
                        data.newEffect.items.map((item, index) => {
                            return <div className="item" key={index}>
                                <div className="title"><span className='num'>{item.num}</span><span className='title'>{item.title}</span></div>
                                <div className="details">
                                    {item.details.map((it, index) => {
                                        return <div className="det" key={index}>
                                            {it}
                                        </div>
                                    })}
                                </div>
                            </div>
                        })
                    }
                </div>
            </>
        }
        {/* {
            tab === 0 && <div className="fixed">
            <h1 className="title">{t('sales')}</h1>
            <div className="descs" style={{}}>
                <a  href="https://npcitem.jd.hk/10046628061834.html" target="blank"><img src={JD} alt="" /></a>
                <a  href="https://www.amazon.co.jp/dp/B09YTQFGSW?ref=myi_title_dp" target="blank"><img src={AMAZON} alt="" /></a>
            </div>
        </div>
            
        } */}
        { i18n.language !== 'ja' && <div className="tips">
            <p>{t("tips1")}</p>
            <p>{t("tips2")}</p>
        </div>}
    </div>
}