import React from 'react';
import './process.less'
import { useTranslation } from 'react-i18next'


function Process(props) {
  const { title, steps } = props
  const {t} = useTranslation()
  const numMapping = {
    1: t("v2.s1"),
    2: t("v2.s2"),
    3: t("v2.s3"),
    4: t("v2.s4"),
    5: t("v2.s5"),
    6: t("v2.s6"),
    7: t("v2.s7"),
    8: t("v2.s8"),
    9: t("v2.s9"),
  }
  return (
    <div className='v2-process' >
      <div className="title">{title}</div>
      {
        steps.map((val, index)=>{
          return <div className="step">
            <div className="num">{numMapping[index+1]}</div>
            <div className="contents">
              <div className='ct'>
                <div className="label">{val.title}</div>
                <div className="detail">
                  {Array.isArray(val.detail)? val.detail.map((val, index)=>{
                    return <p key={index}>{val}</p>
                  }) : val.detail}
                  </div>
              </div>
            </div>
          </div>
        })
      }
    </div>
  );
}

export default Process;
