import React from 'react';
import './p-m.less'
import Ques from './ques';
function PM(props) {
  const { type, num, title, contents, pic } = props
  return (
    <div className='p-m'>
      <Ques num={num} title={title} />
      {type !== 'v' && <div className="content">
        <div className="text">
          {contents.map((val, index)=>{
            return <p key={index}>{val}</p>
          })}
        </div>

        <div className="img">
          <img src={pic} alt="" />
        </div>
      </div>}
      {type === 'v' && <div className="contentv">
        <div className="text">
          {contents.map((val, index)=>{
            return <p key={index}>{val}</p>
          })}
        </div>

        <div className="img">
          <img src={pic} alt="" />
        </div>
      </div>}
    </div>
  );
}

export default PM;
