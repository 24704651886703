import React from "react";

function D1() {
  const texts = [
    "エネルギー源を供給して、はつらつさを保つ",
    "イキイキと元気なカラダをアシスト",
    "内側からのやる気をバックアップ",
    "全身を整え、笑顔が増え、活力ある毎日に",
  ];
  return (
    <div className="product-introduce" style={{ paddingBottom: 0 }}>
      <h1 className="title">
        <span style={{ borderBottom: "2px #195A95 solid" }}>商品特徴</span>
      </h1>
      <h3
        className="title"
        style={{
          textAlign: "center",
          color: "rgb(255, 192, 0)",
          background: "#FAFAFA",
          marginTop: "30px",
          paddingTop: "50px",
          marginBottom: 0,
          paddingBottom: "53px",
        }}
      >
        いつまでも若くて活力ある日々へ。
      </h3>
      <div style={{ background: "#FAFAFA" }}>
        <div style={{ width: "780px", margin: "0 auto 0 auto" }}>
          {texts.map((val, index) => {
            return (
              <p
                style={{
                  fonSize: "14px",
                  textAlign: "left",
                  lineHeight: "24px",
                  margin: 0,
                  padding: "20px 0",
                  background: "#FAFAFA",
                }}
              >
                <span
                  style={{
                    fontFamily: "Apple-Chancery, Apple",
                    color: "#195A95",
                    fontSize: "24px",
                    marginRight: '20px'
                  }}
                >
                  {index + 1}.
                </span>
                {val}
              </p>
            );
          })}
        </div>

        <div className="tips" style={{marginBottom: 0, paddingBottom: '42px'}}>
          <p>*本商品は健康食品です。商品の効果を保証するのもではありません。</p>
          <p>
            *上記はあくまでエビデンスに基づく情報であり、効能効果を保証するものでは一切ありません。
          </p>
        </div>
      </div>
    </div>
  );
}

export default D1;
